<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">意向代理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">意向代理列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl ">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input
                v-model="applyName"
                type="text"
                size="small"
                placeholder="请输入姓名"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div>
            <div title="意向代理区域" class="searchboxItem ci-full">
              <span class="itemLabel" style="width:8rem">意向代理区域:</span>
              <el-cascader
                clearable
                filterable
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
              ></el-cascader>
            </div>
            <div title="公司名称" class="searchboxItem ci-full">
              <span class="itemLabel">公司名称:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                placeholder="请输入公司名称"
                clearable
              />
            </div>
            <div class="btnBox" style="margin-left:20px">
              <el-button
                style="margin-left:20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="姓名"
                align="left"
                prop="applyName"
                show-overflow-tooltip
              />
              <el-table-column
                label="手机号"
                align="left"
                prop="mobile"
                show-overflow-tooltip
              />
              <el-table-column
                label="意向代理区域"
                align="left"
                prop="areaRegion"
                show-overflow-tooltip
              />
              <el-table-column
                label="公司名称"
                align="left"
                prop="compName"
                show-overflow-tooltip
              />
              <el-table-column
                label="公司地址"
                align="center"
                show-overflow-tooltip
                prop="compAddress"
              >
              </el-table-column>
              <el-table-column
                label="经营范围"
                align="center"
                show-overflow-tooltip
                prop="compBusiness"
              >
              </el-table-column>
              <el-table-column
                label="销售人员数量"
                align="right"
                show-overflow-tooltip
                prop="salespersonNum"
              >
              </el-table-column>
              <el-table-column
                label="申请时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | momentDate }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "IntentionalAgencyList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      applyName: "",
      mobile: "",
      compName: "",
      areaId: "",
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      }
    };
  },
  computed: {},
  created() {
    this.getareatree();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        applyName: this.applyName,
        mobile: this.mobile,
        compName: this.compName,
        areaId: this.areaId
      };

      this.doFetch({
        url: "/biz/agent/querylist",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 - 60;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
<style lang="less" scoped>
</style>
